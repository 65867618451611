export enum Role {
    SystemAdministrator = 1,
    StateAdministrator = 2,
    AgencyAdministrator = 3,
    SiteAdministrator= 4,
    Teacher = 5,
    TeacherOfRecord = 7,
    Coach = 8,
    DataEntry_Analyst = 9,
    AffiliateAgency = 10,
    Researcher = 12,
    CustomerService = 13,
    LeadAgencyAdministrator = 14,
    StateReportAdministrator = 15,
    NewHampshireOrganizationsAdminReadOnly = 17,
    TeacherOfRecordEnrollment = 176,
    WestEdPilot = 175
}

export enum RoleGroup {
    UserRoles = 1,
    EnrollmentUser = 2,
    AdministratorRoles = 3,
    LeadAdminRoles = 4,
    PowerUserRoles = 5,
    SystemPowerUser = 6,
    StateUsers = 7
}

export enum RoleGroupAccessLevel {
  UserRoles = 1,
  EnrollmentUser = 2,
  AdministratorRoles = 3,
  StateUsers = 4,
  LeadAdminRoles = 5,
  PowerUserRoles = 6,
  SystemPowerUser = 7
}
