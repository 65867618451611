import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'drdp-invalid-request-modal',
    templateUrl: './invalid-request-modal.component.html',
    styleUrls: ['./invalid-request-modal.component.scss'],
})

export class InvalidRequestModalComponent implements OnInit {
    invalidRequests: any;

    constructor(public dialogRef: MatDialogRef<InvalidRequestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.invalidRequests = this.data;
    }
}