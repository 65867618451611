import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { DrdpLayoutComponent } from './drdp-layout/drdp-layout.component';
import { MyClassesComponent } from './manage-enrollment/my-classes/my-classes.component';
import { StudentsComponent } from './manage-enrollment/my-classes/students/students.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { LatestNewsComponent } from './dashboard/latest-news/latest-news.component';
import { EditUserProfileComponent } from './user-profile/edit-user-profile/edit-user-profile.component';
import { UpdatePasswordComponent } from './user-profile/update-password/update-password.component';
import { AddNewChildComponent } from './add-new-child/add-new-child.component';
import { ViewChildComponent } from './manage-enrollment/view-child/view-child.component';
import { TransferChildComponent } from './manage-enrollment/transfer-child/transfer-child.component';
import { PendingTransfersComponent } from './dashboard/pending-requests/pending-transfers/pending-transfers.component';
import { PendingWithdrawsComponent } from './dashboard/pending-requests/pending-withdraws/pending-withdraws.component';
import { SearchDroppedChildrenComponent } from './search-dropped-children/search-dropped-children.component';
import { PermissionGuard } from '@core/guards/permission.guard';
import { Permission } from '@core/enums/permissions';
import { ReEnrollmentRequestsComponent } from './dashboard/pending-requests/re-enrollment-requests/re-enrollment-requests.component';
import { AddNewsComponent } from './configuration/news/news.component';
import { InputRatingComponent } from './input-rating/input-rating.component';
import { SearchRatingComponent } from './input-rating/search-rating/search-rating.component';
import { ScantronComponent } from './input-rating/components/scantron/scantron.component';
import { PortfolioComponent } from './dashboard/portfolio/portfolio.component';
import { PortfolioClassComponent } from './dashboard/portfolio/portfolio-class/portfolio-class.component';
import { AddSearchChildComponent } from './add-new-child/add-search-child/add-search-child.component';
import { PendingReleasesComponent } from './dashboard/pending-requests/pending-releases/pending-releases.component';
import { PortfolioAddEvidence } from './dashboard/portfolio/portfolio-class/portfolio-add-evidence/portfolio-add-evidence.component';
import { PortfolioReviewEvidenceComponent } from './dashboard/portfolio/portfolio-class/portfolio-review-evidence/portfolio-review-evidence.component';
import { StudentBulkUnEnrollmentComponent } from './manage-enrollment/my-classes/students/students-bulk-un-enrollment/students-bulk-un-enrollment.component';
import { PilotTestChildrenComponent } from './pilot-test-children/pilot-test-children.component';
import { ChildrenComponent } from './children/children.component';
import { CanDeactivateGuard } from '@core/guards/can-deactivate.guard';
import { SearchManageChildComponent } from './manage-enrollment/search-manage-child/search-manage-child.component';
import { PendingChildReEnrollmentComponent } from './dashboard/pending-requests/pending-child-re-enrollment/pending-child-re-enrollment.component';
import { PortfolioMessagesComponent } from './dashboard/portfolio/portfolio-messages/portfolio-messages.component';
import { MessageViewComponent } from './dashboard/portfolio/portfolio-messages/message-view/message-view.component';
import { MessageBoardComponent } from './dashboard/portfolio/portfolio-messages/message-board/message-board.component';
import { ChildRequestComponent } from './manage-enrollment/child-request/child-request.component';
import { TotalLockedChildrenComponent } from './dashboard/total-locked-children/total-locked-children.component';
import { PendingPortfolioEvidencesComponent } from './dashboard/pending-requests/pending-portfolio-evidences/pending-portfolio-evidences.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    component: DrdpLayoutComponent,
    children: [
      {
        path: 'add-child',
        canActivate: [PermissionGuard],
        data: { permission: Permission.CreateStudent },
        component: AddNewChildComponent,
      },
      {
        path: 'add-search-child',
        canActivate: [PermissionGuard],
        data: { permission: Permission.CreateStudent },
        component: AddSearchChildComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
      },
      {
        path: 'update-password',
        component: UpdatePasswordComponent,
      },
      {
        path: 'edit-profile',
        component: EditUserProfileComponent,
      },
      {
        path: 'classrooms',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewMyClasses },
        component: MyClassesComponent,
      },
      {
        path: 'view-students/:id',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewStudent },
        component: StudentsComponent,
      },
      {
        path: 'view-students/:id/bulk-un-enrollment',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewStudent },
        component: StudentBulkUnEnrollmentComponent,
      },
      {
        path: 'transfer-child/:currentStudentId/:id',
        component: TransferChildComponent,
      },
      {
        path: 'manage-news',
        canActivate: [PermissionGuard],
        data: {
          permission: [Permission.CreateNewsItem],
        },
        component: LatestNewsComponent,
      },
      {
        path: 'manage-enrollment',
        data: { permission: Permission.SearchStudent },
        component: SearchManageChildComponent,
      },
      {
        path: 'view-child/:id',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewStudent },
        component: ViewChildComponent,
      },
      {
        path: 'view-child/:id/class/:classId',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewStudent },
        component: ViewChildComponent,
      },
      { path: 'pending-transfers', component: PendingTransfersComponent },
      { path: 'pending-withdraws', component: PendingWithdrawsComponent },
      {
        path: 'pending-child-re-enrollment',
        component: PendingChildReEnrollmentComponent,
      },
      {
        path: 'pending-re-enrollment',
        component: ReEnrollmentRequestsComponent,
      },
      {
        path: 'pending-releases',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewPendingReleaseRequests },
        component: PendingReleasesComponent,
      },
      {
        path: 'search-dropped-children',
        component: SearchDroppedChildrenComponent,
      },
      { path: 'portfolio', component: PortfolioComponent },
      {
        path: 'portfolio/class/:classId/ageGradeInstrument/:ageGradeId',
        component: PortfolioClassComponent,
      },
      { path: 'portfolio/add-evidence', component: PortfolioAddEvidence },
      {
        path: 'portfolio/review-evidence/class/:classId/student-enrollment/:studentClassEnrollmentId/age-grade-instrument/:ageGradeId',
        component: PortfolioReviewEvidenceComponent,
      },
      {
        path: 'portfolio/review-evidence/future-evidence/:futureEvidenceId/student/:studentId/class/:classId/age-grade-instrument/:ageGradeId',
        component: PortfolioReviewEvidenceComponent,
      },
      {
        path: 'newsitems',
        canActivate: [PermissionGuard],
        data: { permission: Permission.CreateNewsItem },
        component: AddNewsComponent,
      },
      {
        path: 'newsitems/:id',
        canActivate: [PermissionGuard],
        data: { permission: Permission.CreateNewsItem },
        component: AddNewsComponent,
      },
      {
        path: 'ratings',
        component: SearchRatingComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewRatings },
      },
      {
        path: 'ratings/class/:classId/ageGradeInstrument/:instrumentId',
        component: SearchRatingComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewRatings },
      },
      {
        path: 'input-ratings/:id',
        component: InputRatingComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: { permission: Permission.ViewRatings },
      },
      {
        path: 'input-scantron/:id',
        component: ScantronComponent,
      },
      {
        path: 'pilot-test-children',
        component: PilotTestChildrenComponent,
        data: { permission: Permission.PilotProgramChildrenPage },
      },
      {
        path: 'children',
        component: ChildrenComponent,
        data: { permission: Permission.AccessToChildrenPAge },
      },
      {
        path: 'portfolio-messages',
        // canActivate: [PermissionGuard],
        // data: { permission: Permission.AccessToPortfolioMessages },
        component: PortfolioMessagesComponent,
        children: [
          {
            path: '',
            component: MessageViewComponent,
          },
          {
            path: 'message-view',
            component: MessageViewComponent,
          },
          {
            path: 'student-enrollment/:studentClassEnrollmentId/student/:studentId/sender/:senderId/recipient/:recipientId',
            component: MessageBoardComponent,
          },
        ]
      },
      {
        path: 'child-requests',
        canActivate: [PermissionGuard],
        data: {
          permission: Permission.AccessChildRequestsPage
        },
        component: ChildRequestComponent,
      },
      {
        path: 'total-locked-children',
        canActivate: [PermissionGuard],
        data: {
          permission: Permission.ViewTotalLockedChildren
        },
        component: TotalLockedChildrenComponent,
      },
      {
        path: 'portfolio-evidence-requests',
        canActivate: [PermissionGuard],
        data: {
          permission: Permission.PendingPortfolioRequests
        },
        component: PendingPortfolioEvidencesComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule { }
