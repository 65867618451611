import { Injectable } from '@angular/core';
import {
  IStudentRequest,
  IStudentRequestBatch,
  IStudentRequestSearch
} from '@core/interfaces/istudent-request';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { DataService } from './data.service';
import { getSearchUrl } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class StudentRequestService {
  constructor(private dataService: DataService) {}

  addStudentRequest(payload: any, message?: string): Observable<any> {
    return this.dataService.post('studentrequest', payload, message);
  }

  addBatchStudentRequests(payload: any, message?: string): Observable<any> {
    return this.dataService.post('studentrequest/batch', payload, message);
  }

  checkDuplicateBatch(payload: any, message?: string): Observable<any> {
    return this.dataService.post(
      'studentrequest/check-duplicate-batch',
      payload,
      message
    );
  }

  getByReason(requestReasonId: number): Observable<any> {
    return this.dataService.get(`studentrequest/${requestReasonId}`);
  }

  getByReasonAndAgency(params: IStudentRequestSearch): Observable<any> {
    const url = getSearchUrl('studentrequest/admin-pending?', params);
    return this.dataService.get(url);
  }

  getApprovedOrPendingByReasonAndClass(params: any): Observable<any> {
    const url = getSearchUrl(
      'studentrequest/approved-or-pending-by-reason-and-class?',
      params
    );
    return this.dataService.get(url);
  }

  pendingTransfersAndDropsExist(
    requests: IStudentRequest[],
    message?: string
  ): Observable<boolean> {
    return this.dataService.post(
      'studentrequest/pending-transfers-or-drops',
      requests,
      message
    );
  }

  updateStudentRequest(payload: any, message?: string): Observable<any> {
    return this.dataService.put('studentrequest', payload, message);
  }

  processBulkUpdates(
    payload: IStudentRequest[],
    message?: string
  ): Observable<any> {
    return this.dataService.put(
      'studentrequest/process-bulk-updates',
      payload,
      message
    );
  }

  processBulkReleaseUpdates(payload: any[], message?: string): Observable<any> {
    return this.dataService.put(
      'studentrequest/process-bulk-release-updates',
      payload,
      message
    );
  }

  processReEnrollmentRequests(
    payload: IStudentRequest[],
    message?: string
  ): Observable<boolean> {
    return this.dataService.put(
      'studentrequest/process-reenrollment',
      payload,
      message
    );
  }

  processTransfers(
    payload: IStudentRequest[],
    message?: string
  ): Observable<boolean> {
    return this.dataService.put(
      'studentrequest/process-transfers',
      payload,
      message
    );
  }

  processBatchRequest(
    payload: IStudentRequestBatch,
    message?: string
  ): Observable<boolean> {
    return this.dataService.post('studentrequest/batch', payload, message);
  }

  processReEnrollmentBatch(
    payload: IStudentRequest[],
    message?: string
  ): Observable<boolean> {
    return this.dataService.put(
      'studentrequest/process-reenrollment-batch',
      payload,
      message
    );
  }

  getHasPendingRequest(requestReasonId: number): Observable<boolean> {
    return this.dataService.get(
      `studentrequest/has-pending-request/${requestReasonId}`
    );
  }

  getStudentRequests(params: any): Observable<any> {
    const url = getSearchUrl('studentrequest/student-requests-page?', params);
    return this.dataService.get(url);
  }

  getStudentEvidenceRequests(params: IStudentRequestSearch): Observable<any> {
    const url = getSearchUrl('studentrequest/evidence-requests?', params);
    return this.dataService.get(url);
  }
}
